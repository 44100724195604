import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import async from "../components/Async";

const LoginPage = async(() => import("../pages/login"));
const OrderDetailPage = async(() => import("../pages/orderDetail"));
const StorePage = async(() => import("../pages/store"));
// const MainPage = async(() => import("../pages/main"));
const ProductPage = async(() => import("../pages/product"));
const OrderPage = async(() => import("../pages/order"));

const Routes = () => (
  <Router>
    <Switch>
      <Route path='/login' name='Login' component={LoginPage} exact />
      <Route path='/' name='Main' component={StorePage} exact />
      <Route path={"/store"} name='Store' component={StorePage} exact />
      <Route path='/product' name='Product' component={ProductPage} exact />
      <Route path='/order' name='Order' component={OrderPage} exact />
      <Route
        path='/order/:orderNo'
        name='OrderDetail'
        component={OrderDetailPage}
        exact
      />

      <Route path='*' component={LoginPage} />
    </Switch>
  </Router>
);

export default Routes;
