export const TRANSLATE_SAFARI_TAP_SHARE = 'Tap "Share"';
export const TRANSLATE_SAFARI_ADD_HOME_SCREEN = 'select "Add to Home Screen"';
export const TRANSLATE_CHROME_ADD_HOME_SCREEN =
  'Click "Add to Home Screen" in your browser menu';
export const TRANSLATE_CHROME_INSTALL =
  "Icon app sẽ được hiển thị trên màn hình của bạn";
export const TRANSLATE_INSTALL_BUTTON = "install";

export const COOKIE_NAME = "add-to-home-screen-pwa";
export const COOKIE_EXPIRE_DAYS = 30;
