import React from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import InstallDialogAction from "./InstallDialogAction";
import Logo from "../../images/logo.png";

export default function InstallDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='dialog-title'>
      <DialogTitle id='dialog-title' style={{ padding: 10 }}>
        {"Cài đặt web App"}
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box display='flex' alignItems='flex-start'>
          <Box mr={1}>
            <img src={Logo} style={{ width: 40, height: 40 }} alt='logo' />
          </Box>
          <Box>
            <Typography variant='subtitle1' style={{ marginTop: 7 }}>
              Phúc Long portal
            </Typography>
          </Box>
        </Box>
        <>
          <Typography variant='body2' component='div'>
            {"Cài đặt web app để tăng trải nghiệm"}
          </Typography>
        </>
      </DialogContent>
      <div style={{ padding: 5 }}>
        <InstallDialogAction
          platform={props.platform}
          onSubmit={props.onSubmit}
          onClose={props.onClose}
        />
      </div>
    </Dialog>
  );
}
