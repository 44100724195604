import styled from "styled-components";

const BaseToastContainer = styled.div`
  position: absolute;
  z-index: 1000;
  color: white;
  flex-direction: row;
  padding: 10px;
  width: 50%;
  left: 30%;
  display: flex;
  top: ${(props) => (props.visible ? 10 : -300)}px;
  cursor: pointer;
  border-radius: 8px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity, top 0.5s;
  background: rgba(0, 111, 60, 0.8);
  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
    top: ${(props) => (props.visible ? 0 : -300)}px;
    border-radius: 0;
  }
`;
export { BaseToastContainer };
