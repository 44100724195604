/* eslint-disable react-hooks/exhaustive-deps */
import { Close } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { COOKIE_EXPIRE_DAYS, COOKIE_NAME } from "../constants";
import InstallDialog from "../InstallDialog";
import InstallDialogAction from "../InstallDialogAction";
import { getPlatform } from "../Platforms";
import "../styles/index.css";
import { getCookieValue, setCookie } from "../utils/cookie";
import { MemoSession } from "../utils/MemoSession";
import Chrome from "./Chrome";
import Install from "./Install";
import Safari from "./Safari";

const memoSession = new MemoSession();

export function AddToHomeScreen({ ...props }) {
  const [component, setComponent] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [eventInstall, setEventInstall] = useState(memoSession.eventInstall);
  const [initData, setInitData] = useState();
  const cookieName = props.cookie.name || COOKIE_NAME;
  const expireDays = props.cookie.expireDays || COOKIE_EXPIRE_DAYS;

  function handleBeforeInstallPrompt(event) {
    event.preventDefault();
    if (!memoSession.eventInstall) {
      setEventInstall(event);
      memoSession.setSession({ eventInstall: event });
    }
  }

  function handleAppInstalled() {
    // onCloseNotify(expireDays + 30);
  }

  function init() {
    const cookieVal = getCookieValue(cookieName);

    if (props.skipFirstVisit && !cookieVal) {
      setCookie(cookieName, 90, "initialized");
      memoSession.setSession({ firstVisit: true });
      return;
    }

    if (memoSession.firstVisit) {
      return;
    }

    const existInstall = "onbeforeinstallprompt" in window;
    const existOnInstalled = "onappinstalled" in window;
    const timeoutInit = existInstall
      ? Math.max(1500, props.delayNotify)
      : props.delayNotify;
    if (existInstall) {
      window.addEventListener(
        "beforeinstallprompt",
        function (beforeInstallPromptEvent) {
          beforeInstallPromptEvent.preventDefault(); // Prevents immediate prompt display
          handleBeforeInstallPrompt(beforeInstallPromptEvent);
          // Shows prompt after a user clicks an "install" button
          // installButton.addEventListener("click", function (mouseEvent) {
          //   // you should not use the MouseEvent here, obviously
          //   beforeInstallPromptEvent.prompt();
          // });

          // installButton.hidden = false; // Make button operable
        },
      );

      // window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    }

    if (existOnInstalled) {
      window.addEventListener("appinstalled", handleAppInstalled);
    }

    const platform = getPlatform();

    const delayTimeout = setTimeout(() => {
      const isSupported = !!platform;
      const isNotNotify = cookieVal !== "notified";
      setInitData({
        platform,
        openNotify: isSupported && isNotNotify && platform !== "standalone",
      });
    }, timeoutInit);

    return () => {
      if (existInstall) {
        window.removeEventListener(
          "beforeinstallprompt",
          handleBeforeInstallPrompt,
        );
      }
      if (existOnInstalled) {
        window.removeEventListener("appinstalled", handleAppInstalled);
      }
      clearTimeout(delayTimeout);
    };
  }

  function onCloseNotify(cookieExpDays = 0) {
    setCookie(cookieName, cookieExpDays || expireDays);
    if (initData) {
      setInitData({ ...initData, openNotify: false });
    }
    setShowDialog(false);
  }

  function onInstallApp(event) {
    event.stopPropagation();
    if (eventInstall) {
      eventInstall
        .prompt()
        .then(() => eventInstall.userChoice)
        .then((choiceResult) => {
          onCloseNotify(
            choiceResult.outcome === "accepted" ? expireDays + 30 : expireDays,
          );
          setEventInstall(undefined);
          setShowDialog(false);
          memoSession.setSession({ eventInstall: undefined });
        })
        .catch(() => {
          onCloseNotify();
          alert("Browser not supported");
        });
    } else {
      onCloseNotify();
      alert("Browser not supported");
    }
  }

  function renderComponent() {
    if (!initData || !initData.openNotify) {
      return setComponent(() => null);
    }

    setComponent(RenderHOC);
  }

  function handleClose() {
    setEventInstall(undefined);
    setShowDialog(false);
  }

  function RenderHOC() {
    return (
      <div className='add-to-home-screen-pwa' style={props.styles.body}>
        {eventInstall ? <RenderComponentInstall /> : <RenderComponentNotify />}
      </div>
    );
  }

  function RenderComponentInstall() {
    return (
      <div className='add-to-home-screen-pwa__notify'>
        <Close
          onClick={() => onCloseNotify()}
          style={{
            position: "absolute",
            top: 1,
            right: 10,
            cursor: "pointer",
            color: "#636060",
          }}
        />
        <Install
          onClick={() => setShowDialog(true)}
          translate={props.translate}
          styles={props.styles}
        />
      </div>
    );
  }

  function RenderComponentNotify() {
    let ComponentNotify = null;
    if (initData?.platform === "chrome-android") {
      ComponentNotify = (
        <Chrome translate={props.translate} styles={props.styles} />
      );
    } else if (initData?.platform === initData?.platform.IDEVICE) {
      ComponentNotify = (
        <Safari translate={props.translate} styles={props.styles} />
      );
    }

    return ComponentNotify ? (
      <div
        className='add-to-home-screen-pwa__notify'
        onClick={() => onCloseNotify()}>
        {ComponentNotify}
        <InstallDialogAction
          platform={props.platform}
          onSubmit={props.onSubmit}
          onClose={props.onClose}
        />
      </div>
    ) : null;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    init();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(renderComponent, [initData, eventInstall]);

  return (
    <>
      {component}
      <InstallDialog
        open={showDialog}
        onSubmit={onInstallApp}
        onClose={handleClose}
        platform={initData?.platform}
        {...eventInstall}
      />
    </>
  );
}

AddToHomeScreen.defaultProps = {
  delayNotify: 0,
  skipFirstVisit: true,
  cookie: {},
  translate: {},
  styles: {},
};

AddToHomeScreen.propTypes = {
  delayNotify: PropTypes.number,
  skipFirstVisit: PropTypes.bool,
  cookie: PropTypes.shape({
    name: PropTypes.string,
    expireDays: PropTypes.number,
  }),
  translate: PropTypes.shape({
    headline: PropTypes.string,
    bottomline: PropTypes.string,
    tapShare: PropTypes.string,
    addHomeScreen: PropTypes.string,
    chromiumInstruction: PropTypes.string,
    chromiumInstall: PropTypes.string,
    buttonInstall: PropTypes.string,
  }),
  styles: PropTypes.shape({
    body: PropTypes.object,
    button: PropTypes.object,
    heading: PropTypes.object,
  }),
};
