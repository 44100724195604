import React from 'react';
import { IntlProvider } from 'react-intl';
import Cookie from 'js-cookie';
import { isLocale } from './language.utils';


const LanguageContext = React.createContext({});

export const LanguageProvider = ({ children, messages }) => {
  const [locale, setLocale] = React.useState('vi');
  const changeLanguage = (newLocale) => {
    setLocale(newLocale);
    document.documentElement.lang = newLocale;
    Cookie.set('locale', newLocale);
  };
  React.useEffect(() => {
    const localSetting = Cookie.get('locale');
    if (localSetting && isLocale(localSetting)) {
      document.documentElement.lang = localSetting;
      setLocale(localSetting);
    }
  }, [locale]);
  return (
    <LanguageContext.Provider value={{ locale, changeLanguage }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLocale = () => React.useContext(LanguageContext);
