import firebase from "firebase/app";
import "firebase/messaging";
import { showAlert } from "../libraries/BaseAlert";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const FirebaseUltils = {
  //checking whether token is available in indexed DB
  tokenInlocalforage: async () => {
    return localStorage.getItem("FIREBASE_TOKEN");
  },
  //initializing firebase app
  init: async function () {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      try {
        const tokenInLocalForage = await this.tokenInlocalforage();
        //if FCM token is already there just return the token
        if (tokenInLocalForage !== null) {
          firebase.messaging().onMessage((message) => {
            showAlert("");
            console.log(" ~ message", message);
            // alert("ác");
          });
          return tokenInLocalForage;
        }
        //requesting notification permission from browser
        const status = await Notification.requestPermission();
        if (status && status === "granted") {
          //getting token from FCM
          try {
            const fcm_token = await firebase.messaging().getToken({
              vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
            });
            if (fcm_token) {
              //setting FCM token in indexed db using localforage
              localStorage.setItem("FIREBASE_TOKEN", fcm_token);
              console.log("fcm token", fcm_token);
              //return the FCM token after saving it
              return fcm_token;
            }
          } catch (error) {
            console.log("file: firebase-ultils.ts ~ line 51 ~ error", error);
          }
        }
      } catch (error) {
        console.log("line 64 ~ error", error);
        console.error(error);
        return null;
      }
    } else {
      const status = await Notification.requestPermission();
      if (status && status === "granted") {
        //getting token from FCM
        try {
          const fcm_token = await firebase.messaging().getToken({
            vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
          });
          if (fcm_token) {
            //setting FCM token in indexed db using localforage
            localStorage.setItem("FIREBASE_TOKEN", fcm_token);
            console.log("fcm token", fcm_token);
            //return the FCM token after saving it
            return fcm_token;
          }
        } catch (error) {
          console.log("file: firebase-ultils.ts ~ line 51 ~ error", error);
        }
      }
    }
  },
  logoutFirebase: async function () {
    // firebase.messaging().deleteToken();
    // firebase.app().delete();
  },
};
export { FirebaseUltils };
// const FirebaseUltils = firebase;
// export { FirebaseUltils };
