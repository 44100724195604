import { createMuiTheme } from "@material-ui/core/styles";
import breakpoints from "./breakpoints";
import overrides from "./overrides";
import props from "./props";
import typography from "./typography";

const createTheme = () => {
  return createMuiTheme({
    spacing: 4,
    breakpoints: breakpoints,
    overrides: overrides,
    props: props,
    typography: typography,
    palette: {
      primary: {
        main: "#006F3C",
      },
      secondary: {
        main: "#ff5b60",
      },
    },
    // shadows: shadows,
  });
};

export default createTheme;
