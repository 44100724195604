import Manager from "./Manager";

export const ALERT_TYPE = {
  DEFAULT: "DEFAULT",
  ACCOUNT_EXISTS: "ACCOUNT_EXISTS",
  CONTACT_MANAGER: "CONTACT_MANAGER",
  BASE_ALERT: "BASE_ALERT",
  DEPLOY_FUNCTION: "DEPLOY_FUNCTION",
};

export function showAlert(message, options) {
  const ref = Manager.getDefault();

  if (!!ref) {
    ref.showModal(message, options);
  }
}

export function showAlertByType(params) {
  const ref = Manager.getDefault();

  if (!!ref) {
    ref.onShowByType(params);
  }
}

export function hideAlert() {
  const ref = Manager.getDefault();

  if (!!ref) {
    ref.onClose();
  }
}
