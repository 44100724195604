const overrides = {
  MuiTableHead: {
    root: {
      backgroundColor: "#ccc",
    },
  },
  MuiDialogTitle: {
    root: {
      padding: "0px",
    },
  },
  MuiDialogContent: {
    dividers: {
      borderTop: "none",
      borderBottom: "none",
      padding: "0px 24px"
    },
  },
  MuiChip: {
    root: {
      borderRadius: "6px",
    },
  },
  MuiFormHelperText: {
    root: {
      color: "#f44336",
    },
  },
};

export default overrides;
