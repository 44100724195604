export function setCookie(name, days, val = "notified") {
  const currentDate = new Date();
  const expires = new Date(currentDate.setDate(currentDate.getDate() + days));
  document.cookie = `${name}=${val}; expires=${expires.toUTCString()};`;
}

export function getCookieValue(name) {
  const b = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}
