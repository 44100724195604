import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { Fragment } from "react";

const SnackbarContext = React.createContext({});

export const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("info");
  const [message, setMessage] = React.useState("1111");

  const showSnackbar = ({ type, message }) => {
    setOpen(true);
    setType(type);
    setMessage(message);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  React.useEffect(() => {}, [open]);
  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <Fragment>
        <Snackbar
          className='snackbar-common'
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}>
          <Alert
            style={{
              backgroundColor: type === "success" ? "#4caf50" : "info",
            }}
            onClose={handleClose}
            variant='filled'
            severity={type}>
            {message}
          </Alert>
        </Snackbar>

        {children}
      </Fragment>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => React.useContext(SnackbarContext);
