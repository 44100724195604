class Manager {
  _baseAlert = null;

  register(_ref) {
    if (!this._baseAlert) {
      this._baseAlert = _ref;
    }
  }

  unregister(_ref) {
    if (this._baseAlert && this._baseAlert._id === _ref._id) {
      this._baseAlert = null;
    }
  }

  getDefault() {
    return this._baseAlert;
  }
}

export default new Manager();
