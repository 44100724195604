import {
  Box as MuiBox, Breadcrumbs as MuiBreadcrumbs, Button as MuiButton, Card as MuiCard,
  CardContent as MuiCardContent, Divider as MuiDivider, Grid as MuiGrid, Paper as MuiPaper, TableCell as MuiTableCell, TextField as MuiTextField, Typography as MuiTypography
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";

export const Button = styled(MuiButton)(spacing);
export const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
export const Paper = styled(MuiPaper)(spacing);
export const Typography = styled(MuiTypography)(spacing);
export const Grid = styled(MuiGrid)(spacing);
export const Box = styled(MuiBox)(spacing);
export const TableCell = styled(MuiTableCell)`
  // padding-left: 12px;
`;
export const Divider = styled(MuiDivider)(spacing);
export const Card = styled(MuiCard)(spacing);
export const CardContent = styled(MuiCardContent)(spacing);
export const CardWithBorder = styled(MuiCard)`
  padding-top: 0px;
  padding-bottom: 0px;
  border: 1px solid #d9d9d9;
  margin-top: 12px;
  margin-bottom: unset;
  > .MuiCardContent-root {
    padding: 16px;
  }
`;
export const TextField = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: "6px 12px",
      height: "27px",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#ccc',
      },
      '&:hover fieldset': {
        borderColor: '#ccc',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#66afe9',
        borderWidth: "1px",
        outline: 0,
        boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)",
      },
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      paddingTop: "0px",
      paddingBottom: "0px",
      '& .MuiAutocomplete-input': {
        paddingTop: "6px",
        paddingBottom: "6px",
      },
    }
  },
})(MuiTextField);

export const MainWrapper = styled.div`
  // position: relative;
  
`;
export const FormWrapper = styled.div`
  display: flex;
  padding: 15px 25px;
  .flag{
    cursor: pointer;
    margin-left: 10px;
  }
  .btn-checkbill{
    width: 90%;
    height: 50px;
    border-radius: 40px;
    margin-top: 20px;
  }
  .img-guide{
    width: 100%;
  }
`;
export const FormLabel = styled.div`
  text-align: left;
  padding-top: 10px;
  padding-bottom: 2px;
  span{
    font-style: italic;
    color: red;
  }
`;
export const FormHeader = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 10px;
`;
export const FormBold = styled.div`
  text-align: left;
  color: #000;
  padding-top: 15px;
`;

export const FormNotice = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: left;
  color: red;
  font-size: 16px;
`;