import {
  TRANSLATE_CHROME_INSTALL,
  TRANSLATE_INSTALL_BUTTON,
} from "../constants";

export default function Safari({ translate, onClick, styles }) {
  return (
    <>
      {translate.headline ? (
        <p className='heading' style={styles.heading}>
          {translate.headline}
        </p>
      ) : null}
      <p>{translate.chromiumInstall || TRANSLATE_CHROME_INSTALL}</p>
      <button onClick={onClick} style={styles.button}>
        {translate.buttonInstall || TRANSLATE_INSTALL_BUTTON}
      </button>
    </>
  );
}
