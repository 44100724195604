import { Close } from "@material-ui/icons";
import React, { useImperativeHandle, useState } from "react";
import { BaseToastContainer } from "./base-alert.style";

function BaseAlert(props, ref) {
  const [messsage, setMessage] = useState({ message: "", visible: false });
  const showModal = (message, options) => {
    setMessage({
      message,
      visible: true,
    });
  };

  useImperativeHandle(
    ref,
    () => ({
      showModal,
    }),
    [],
  );

  return (
    <BaseToastContainer visible={messsage.visible}>
      <div
        onClick={() => {
          setMessage({
            ...messsage.message,
            visible: false,
          });
          window.location.href = "/order";
        }}
        style={{ flex: 1 }}>
        Bạn có đơn hàng mới, Click thông báo để xem!
      </div>

      <Close
        onClick={() => {
          setMessage({
            ...messsage.message,
            visible: false,
          });
        }}
        style={{ color: "white", marginRight: 15 }}
      />
    </BaseToastContainer>
  );
}

export default React.forwardRef(BaseAlert);
