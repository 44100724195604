const defineColor = {
  headline: "#00447C",
  subtitle: "#1A1A1A",
};
const typography = {
  fontFamily: [
    "Baloo 2",
    "Roboto",
    "sans-serif",
  ].join(","),
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: "96px",
    fontWeight: 300,
    lineHeight: "144px",
    color: defineColor["headline"],
  },
  h2: {
    fontSize: "60px",
    fontWeight: 300,
    lineHeight: "90px",
    color: defineColor["headline"],
  },
  h3: {
    fontSize: "48px",
    fontWeight: 500,
    lineHeight: "72px",
    color: defineColor["headline"],
  },
  h4: {
    fontSize: "34px",
    fontWeight: 500,
    lineHeight: "51px",
    color: defineColor["headline"],
  },
  h5: {
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "36px",
    color: defineColor["headline"],
  },
  h6: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "30px",
    color: defineColor["headline"],
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    color: defineColor["subtitle"],
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "21px",
    color: defineColor["headline"],
  },
  subtitle3: {
    fontSize: 12,
    // fontWeight: 400,
    // lineHeight: "21px",
    // color: defineColor["headline"],
  },
  body1: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "21px",
    color: defineColor["subtitle"],
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "21px",
    color: defineColor["subtitle"],
  },
  button: {
    fontSize: 14,
    textTransform: "none",
  },
};

export default typography;
