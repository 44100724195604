export class MemoSession {
  _session = { firstVisit: false };

  get firstVisit() {
    return this._session.firstVisit;
  }

  get eventInstall() {
    return this._session.eventInstall;
  }

  setSession(data) {
    this._session = { ...this._session, ...data };
  }
}
